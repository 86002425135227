<template>
  <section class="invoice-add-wrapper">
    <b-row class="invoice-add">
      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="12"
        md="12"
      >
        <b-form @submit.stop.prevent="">
          <b-card
            class="invoice-preview-card"
            title="Editar comisión"
          >
            <!-- Item Section -->
            <b-overlay
              :show="tableOverlay"
              rounded="sm"
            >
              <div
                ref="form"
                class="repeater-form"
                :style="{height: 10}"
              >
                <b-row
                  ref="row"
                  class="pb-2"
                >
                  <!-- Item Form -->
                  <b-col cols="12">
                    <!-- Item ROW -->
                    <div class="d-flex border rounded">
                      <b-row class="flex-grow-1 p-2">
                        <!-- AGENTE -->
                        <b-col lg="4" sm="12">
                          <!-- <label class="d-inline d-lg-none">Nombre del agente</label> -->
                          <b-form-group
                            label="Nombre del agente"
                          >
                            <b-form-input
                              id="agentName"
                              name="agentName"
                              class="mb-2"
                              placeholder="Escribir nombre"
                              @keypress="detectUnsavedChange"
                              v-model="commissionData.name"
                            />
                          </b-form-group>
                        </b-col>
                        <!-- ROL -->
                        <b-col lg="4" sm="12">
                          <!-- <label class="d-inline d-lg-none">Rol del agente</label> -->
                          <b-form-group
                            label="Rol del agente"
                          >
                            <v-select
                              id="role"
                              name="role"
                              label="name"
                              class="mb-2 item-selector-title"
                              placeholder="Seleccionar rol"
                              :options="actions"
                              :clearable="false"
                              :reduce="name => name.id"
                              v-on:input="detectUnsavedChange"
                              v-model="commissionData.idAction"
                            />
                          </b-form-group>
                        </b-col>
                        <!-- DEADLINE DAY -->
                        <b-col lg="4" sm="12">
                          <b-form-group
                            label="Día de pago"
                          >
                            <v-select
                              id="deadline"
                              name="deadline"
                              label="name"
                              class="mb-2 item-selector-title"
                              placeholder="Seleccionar día de pago"
                              :options="deadlineDays"
                              :clearable="false"
                              :reduce="name => name.id"
                              v-on:input="detectUnsavedChange"
                              v-model="commissionData.deadlineDay"
                            />
                          </b-form-group>
                        </b-col>
                        <!-- Linea -->
                        <b-col cols="12">
                          <hr style="height:1px;border-width:0;color:gray;background-color:gray">
                          <br>
                        </b-col>
                        <!-- EXPENSE -->
                        <b-col>
                          <div>
                            <div>
                              <b-form
                                ref="form"
                                class="repeater-form"
                              >
                                <!-- Row Loop -->
                                <b-row
                                  v-for="(commission, indexCommission) in commissionData.expenses"
                                  :id="commission.idCommission"
                                  :key="indexCommission"
                                  ref="row"
                                >
                                  <!-- Gasto -->
                                  <b-col lg="6" sm="12">
                                    <!-- <label class="d-inline d-lg-none">Gasto</label> -->
                                    <b-form-group
                                      label="Gasto"
                                      label-for="Gasto"
                                    >
                                      <v-select
                                        id="expense"
                                        name="expense"
                                        label="name"
                                        placeholder="Selecciona un gasto"
                                        :options="expensesData"
                                        :clearable="false"
                                        :reduce="name => name.id"
                                        v-on:input="detectUnsavedChange"
                                        v-model='commissionData.expenses[indexCommission].idExpense'
                                      />
                                    </b-form-group>
                                  </b-col>
                                  <!-- Precio -->
                                  <b-col lg="4">
                                    <!-- <label class="d-inline d-lg-none">Precio</label> -->
                                    <b-form-group
                                      label="Precio"
                                      label-for="Precio"
                                    >
                                      <b-form-input
                                        id="price"
                                        name="price"
                                        class="mb-2"
                                        placeholder="Escribir precio"
                                        @keypress="restrictNumber($event)"
                                        v-model="commissionData.expenses[indexCommission].price"
                                      />
                                    </b-form-group>
                                  </b-col>
                                  <!-- Eliminar -->
                                  <b-col lg="2" class="mb-3" style="text-align: center;">
                                    <b-button
                                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                                      variant="outline-danger"
                                      class="mt-0 mt-md-2"
                                      @click="removeItem(indexCommission)"
                                    >
                                      <feather-icon
                                        icon="XIcon"
                                        class="mr-25"
                                      />
                                      <span>Eliminar</span>
                                    </b-button>
                                  </b-col>
                                </b-row>
                              </b-form>
                            </div>
                            <!-- Agregar -->
                            <div v-if="commissionData.expenses.length == 0" style="text-align: center;">
                              <b-button
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                variant="outline-primary"
                                @click="addNewItem()"
                              >
                                <feather-icon
                                  icon="PlusIcon"
                                  class="mr-25"
                                />
                                <span>Agregar un gasto</span>
                              </b-button>
                            </div>
                            <div v-else style="text-align: center;">
                              <br>
                              <b-button
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                variant="outline-primary"
                                @click="addNewItem()"
                              >
                                <feather-icon
                                  icon="PlusIcon"
                                  class="mr-25"
                                />
                                <span>Agregar otro gasto</span>
                              </b-button>
                            </div>
                          </div>
                        </b-col>
                      </b-row>
                    </div>
                  </b-col>
                </b-row>
                <div v-if="commissionData.expenses.length != 0" class="d-flex justify-content-center">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    @click="saveCommission"
                  >
                    <div v-if="!saveButtonStatus">
                    <feather-icon
                      icon="SaveIcon"
                      class="mr-25"
                    />
                    Guardar comisión
                    </div>
                    <div v-if="saveButtonStatus">
                      <b-spinner small/>
                      &nbsp; Guardando comisión...
                    </div>
                  </b-button>
                </div>
              </div>
            </b-overlay>
          </b-card>
        </b-form>
      </b-col>
    </b-row>
    <!-- MODAL ALERTA -->
    <b-modal
      id="alertModal"
      title="Alerta"
      ok-title="Voy a revisarlo"
      ok-only
      centered
      no-close-on-backdrop
      hide-header-close
    >
      <b-alert
        variant="danger"
        show
      >
        <h4 class="alert-heading">
          Faltan datos
        </h4>
        <div class="alert-body font-medium-2">
          <p>Favor de llenar todos los campos.</p>
        </div>
      </b-alert>
    </b-modal>
  </section>
</template>


<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
      color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}

br {
  user-select: none;
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '~@core/scss/base/components/variables-dark';

.form-item-section {
background-color:$product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }
}
</style>


<script>
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import utils from '../../../../utils/utils.js';
import { Spanish } from "flatpickr/dist/l10n/es.js"
flatpickr.localize(Spanish);
import Logo from '@core/layouts/components/Logo.vue'
import { ref, onUnmounted } from '@vue/composition-api'
import { heightTransition } from '@core/mixins/ui/transition'

// temp
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

import { BTabs, BTab, BButton, BForm, BFormGroup, BSpinner,
BFormInput, BRow, BCol, BCard, BCardHeader, BCardTitle, BCardText, 
BCardBody, BInputGroup, BInputGroupAppend, BInputGroupPrepend, BOverlay,
BFormCheckbox, VBTooltip, BAlert, BFormTextarea, BPopover, VBToggle } from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import router from '@/router'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'



export default {
  components: {

    BAlert,
    BTabs,
    BTab,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BFormCheckbox,
    BCardBody,
    BFormTextarea,
    BPopover,
    flatPickr,
    vSelect,
    Logo,
    VueApexCharts,
    BSpinner,
    BOverlay,

  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  mixins: [heightTransition],
  data() {
    return {

      expensesData: [],
      actions: [],
      deadlineDays: [
        {
          name: 'Lunes', 
          id: 1
        }, 
        {
          name: 'Martes', 
          id: 2
        }, 
        {
          name: 'Miércoles', 
          id: 3
        }, 
        {
          name: 'Jueves', 
          id: 4
        }, 
        {
          name: 'Viernes', 
          id: 5
        }, 
        {
          name: 'Sábado', 
          id: 6
        }, 
        {
          name: 'Domingo', 
          id: 7
        }
      ],


      // Objeto a llenar con los campos de pantalla
      commissionData: {
        idCommissionAgent: String(router.currentRoute.params.id),
        name: null,
        idAction: null,
        deadlineDay: null,
        expenses: [],
      },

      // Overlay
      tableOverlay: true,

      // Botón de guardado
      saveButtonStatus: false,
      byPassRouteLeave: true,
    }
  },
  created() {
    this.getExpenses();
  },
  beforeRouteLeave(to, from, next) { // Evitar que se salgan de la pantalla y perder datos
    if (!(this.byPassRouteLeave)) {
      const answer = window.confirm('¿Seguro que desea salir? Los cambios no guardados se perderán.');
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
  methods: {
    getExpenses() {
      this.$axios.get(this.$services + 'expenses/get_expenses', {
        params: {
          type: 'COMMISSIONS'
        }
      }).then(res => {
        this.$refreshToken(res.headers['x-access-token']);
        if (!res.data.error) {
          this.expensesData = res.data.data;
          this.getActions();
        } else
        throw 'Error al obtener gastos.';
      }).catch(error => {
        if (typeof error != 'string') {
          this.$verifyToken(error.response.data.data);
        } this.showToast('danger', String(error));
      });
    },
    getActions() {
      this.$axios.get(this.$services + 'general/get_actions', {
        params : {
          type: "COMISIONISTA"
        }
      }).then(res => {
        this.$refreshToken(res.headers['x-access-token']);
        if (!res.data.error) {
          this.actions = res.data.data;
          this.getCommissionsByAgent();
        } else
        throw 'Error al obtener gastos.';
      }).catch(error => {
        if (typeof error != 'string') {
          this.$verifyToken(error.response.data.data);
        } this.showToast('danger', String(error));
      });
    },
    getCommissionsByAgent() {
      this.$axios.get(this.$services + 'commissions/get_commissions_by_agent', {
        params: {
          idCommissionAgent: String(router.currentRoute.params.id),
        }
      }).then(res => {
        this.$refreshToken(res.headers['x-access-token']);
        if (!res.data.error) {
          this.commissionData.name = res.data.data[0].name;
          this.actions.forEach(role => {
            if (role.name.toUpperCase() == res.data.data[0].role.toUpperCase())
              this.commissionData.idAction = role.id
          });
          this.deadlineDays.forEach(day => {
            if (day.id === res.data.data[0].deadlineDay)
              this.commissionData.deadlineDay = day.id
          });
          res.data.data[0].commissions.forEach(expense => {
            this.addNewItem(expense.idExpense, expense.price);
          });
        } else
        throw 'Error al obtener datos.'
      }).catch(error => {
        if (typeof error != 'string') {
          this.$verifyToken(error.response.data.data);
        } this.showToast('danger', String(error));
      }).finally(() => {
        this.tableOverlay = false;
      });
    },
    updateCommission() {
      this.saveButtonStatus = true;
      this.tableOverlay = true,
      this.$axios.post(this.$services + 'commissions/update_agent_commissions', {
        commissionData: JSON.parse(JSON.stringify(this.commissionData)),
      }).then(res => {
        this.$refreshToken(res.headers['x-access-token']);
        if (!res.data.error) {
          this.showToast('success', '👋  ¡Comisión actualizada con éxito!');
        } else
        throw 'Error al actualizar comisión.'
      }).catch(error => {
        if (typeof error != 'string') {
          this.$verifyToken(error.response.data.data);
        } this.showToast('warning', 'Puede que algunos gastos no fueran actualizados porque están siendo usados en otra pantalla.');
      }).finally(() => {
        this.saveButtonStatus = false;
        this.byPassRouteLeave = true;
        this.$router.push({ name: 'commissions' });
      });
    },
    saveCommission() {
      this.checkMissingParameters() ? this.$bvModal.show('alertModal') : this.updateCommission();
    },
    checkMissingParameters() {

      // Checar nombre y rol
      if (
        this.commissionData.name == null ||
        this.commissionData.name === '' ||
        this.commissionData.idAction == null ||
        this.commissionData.deadlineDay == null
      )
        return true;

      // Checar gastos y precios
      for (const expense of this.commissionData.expenses)
        if (
          expense.idExpense == null ||
          expense.price == null ||
          expense.price === ''
        )
          return true;

      return false;  // No falta ningún parámetro
    },
    addNewItem(idExpenseValue = null, priceValue = null) {
      this.commissionData.expenses.push(JSON.parse(JSON.stringify({
        idExpense: idExpenseValue,
        price: priceValue,
      })));
    },
    removeItem(indexCommission) {
      this.commissionData.expenses.splice(indexCommission, 1);
    },
    detectUnsavedChange() {
      this.byPassRouteLeave = false;
    },
    showToast(variant, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Notificación',
          icon: 'BellIcon',
          text,
          variant,
        },
      })
    },
    restrictNumber(event) { // Sólo números pueden ser ingresados
      this.byPassRouteLeave = false;
      let keyCode = (event.keyCode ? event.keyCode : event.which);
      let stringValue = event.target.value.toString();
      if ((keyCode < 48 || keyCode > 57) && (keyCode !== 46 || stringValue.indexOf('.') != -1)) { // 46 is dot
        event.preventDefault();
      }
    },
  },
  computed: {
  },
}
</script>
